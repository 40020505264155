<template>
   <v-container 
      class="px-0"
      style="height:100%;padding-top:54px;max-width:3600px;background-color:transparent;overflow-y:auto;">
      <!-- Report Builder -->
      <v-row justify='left'
         class="px-0 ma-0 mt-0 pb-0 pt-0">

         <v-col cols="12"
            class="pa-0">
            <v-card 
               flat class="mt-1"
               style="background-color:white;border-radius:0px;">
               <v-card-title 
                  class="px-3 py-1"
                  style="color:#6D4C41;" >
                  <div class="mr-2 mt-2"
                     style="display:inline-block;vertial-align:top;">
                     <v-icon v-if="1==2"
                        class="mr-1"
                        color="#BDBDBD" style="font-size:34px;">
                        mdi-calendar-range</v-icon>
                     <div
                        style="display:inline-block;vertial-align:top;min-width:320px;max-width:320px;height:40px;">
                        <date-picker
                           v-model='dateType' 
                           @dateChanged='onDateChanged'/>
                     </div>
                  </div>

                  <div class="mr-2 mt-2"
                     style="display:inline-block;vertial-align:top;">
                     <div class="mr-1"
                        style="display:inline-block;font-size:14px;color:#888888;">
                        <b>Step:</b>
                     </div>
                     <div style="display:inline-block;">
                        <v-select
                           :items="allIntervals"
                           v-model="activeInterval"
                           item-text ="label"
                           item-value ="valueInMin"
                           label="" color="primary"
                           style="width:100%;max-width:110px;border-width: 1.5px;"
                           outlined dense single-line hide-details
                        >
                           <template v-slot:selection="{ item }">
                              <div style="text-align:left;padding-left:8px;width:100%;">
                                 {{item.label}}
                              </div>
                           </template>
                        </v-select>
                     </div>
                  </div>

                  <div class="mt-2 ml-6 mr-6"
                     style="display:inline-block;vertial-align:top;">
                     <v-btn icon
                        @click="isBuildChart=false;isBuildTable=true">
                        <v-icon :color="isBuildTable?'#E53935':'#CCCCCC'">
                           mdi-table
                        </v-icon>
                     </v-btn>
                     <v-btn icon
                        @click="isBuildChart=true;isBuildTable=false"
                        class="mr-2">
                        <v-icon :color="isBuildChart?'#E53935':'#CCCCCC'">
                           mdi-chart-line
                        </v-icon>
                     </v-btn>
                  </div>

                  <v-btn dark 
                     color="#8D6E63"
                     class="mt-2 pl-2 pr-2 text-none"
                     @click="dialogPickPoints=true">
                     <v-icon color="white" class="mr-1"
                        style="font-size:20px;">
                        mdi-magnify-plus-outline
                     </v-icon>
                     Chọn Point
                  </v-btn>

                  <v-spacer/>

                  <div class="mt-2 ml-6 mr-0"
                     style="display:inline-block;vertial-align:top;">
                     <v-btn light 
                        color="#01579B"
                        class="pl3 pr-2 text-none"
                        style="color:white;"
                        @click="buildReport(1)"
                        :disabled="!(isBuildChart || isBuildTable) || !points || points.length<=0"
                        >
                        Build
                        <v-icon color="white" class="ml-1"
                           style="font-size:20px;">
                           mdi-play
                        </v-icon>
                     </v-btn>
                  </div>
               </v-card-title>
               <!-- points -->
               <v-card-text class="px-0 py-1 pb-2">
                  <v-row class="py-0 ma-0 px-4">
                     <v-col align="left" v-if="points.length<=0" 
                        class="pt-1 pb-2 px-1"
                        style="color:#AAAAAA;">
                        <i>Chưa chọn point</i>
                     </v-col>
                     <v-col cols="3"
                        style="max-width:140px;"
                        class="pt-1 pb-2 px-1"
                        v-for="(point, index) in points" :key="index"
                     >
                     <div 
                        :style="{'background-color': point.color}"
                        style="width:100%;display: table; height: 32px; overflow: hidden;border-radius:4px;">
                        <div style="display: table-cell;vertical-align: middle;">
                           <div 
                              style="color:white;font-size:12px;line-height:12px;">
                              {{point.name}} ({{point.unit}})
                           </div>
                        </div>
                     </div>
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>

         <v-dialog v-model="dialogPickPoints">
            <point-picker @onPointPicked="onPointPicked"/>
         </v-dialog>

         <v-col v-if="1==2"
            class="py-0 px-0"
            :class="{'pa-0': $vuetify.breakpoint.smAndDown }"
            style='min-width:300px;max-width:560px;'>
            <v-card >
               <v-card-title v-if="1==1"
                  class="py-1" 
                  style='background-color:#1565C0;color:white;'
                  >
                  Report Builder
               </v-card-title>

               <v-card-text 
                  :class="{'pa-0': $vuetify.breakpoint.smAndDown }"
                  style='min-height:60px;border-bottom: 1px solid #CCCCCC;'>

                  <v-container fluid style="font-size:18px;">
                     <v-row class="pa-0 ma-0" justify='start'>
                        <v-col
                           cols='12' xs="12" sm="12" md="3" lg="3" xl="3"
                           style="" class="pa-1"
                           :class="{'reportLabel': $vuetify.breakpoint.mdAndUp }">
                           Select Date
                        </v-col>

                        <v-col cols='12' xs="12" sm="12" md="9" lg="9" xl="9"
                           class="pa-1"
                           style="">
                           <date-picker v-model='dateType' @dateChanged='onDateChanged'/>
                        </v-col>
                     </v-row>

                     <v-row class="pa-0 ma-0" justify='start'>
                        <v-col
                           cols='6' xs="6" sm="6" md="3" lg="3" xl="3"
                           style="" class="pa-1 reportLabel">
                           Data Interval
                        </v-col>

                        <v-col
                           cols='6' xs="6" sm="6" md="9" lg="9" xl="9"
                           class="pa-1">
                           <v-select
                              :items="allIntervals"
                              v-model="activeInterval"
                              item-text ="label"
                              item-value ="valueInMin"
                              label="" color="primary"
                              style="width:100%;max-width:165px;border-width: 1.5px;"
                              outlined dense single-line hide-details
                           >
                              <template v-slot:selection="{ item }">
                                 <div style="text-align:left;padding-left:8px;width:100%;">
                                    {{item.label}}
                                 </div>
                              </template>
                           </v-select>
                        </v-col>
                     </v-row>

                     <v-row v-if="1==2" class="pa-0 ma-0" justify='start'>
                        <v-col
                           cols='6' xs="6" sm="6" md="3" lg="3" xl="3"
                           style="" class="reportLabel pa-1 py-0 my-0">
                           Build Chart
                        </v-col>

                        <v-col
                           cols='6' xs="6" sm="6" md="9" lg="9" xl="9"
                           class="pa-0 my-0"
                           style="">
                           <v-checkbox
                              v-model="isBuildChart"
                              label=""
                              style=""
                              hide-details class="my-0"
                           ></v-checkbox>
                        </v-col>

                        <v-col cols='6' xs="6" sm="6" md="3" lg="3" xl="3"
                           style="" class="reportLabel pa-1 py-0 my-0">
                           Build Table
                        </v-col>

                        <v-col cols='6' xs="6" sm="6" md="9" lg="9" xl="9"
                           class="pa-0 my-0"
                           style="">
                           <v-checkbox
                              v-model="isBuildTable"
                              label=""
                              hide-details class="my-0"
                           ></v-checkbox>
                        </v-col>
                     </v-row>

                  </v-container>

               </v-card-text>

               <v-card-actions>
                  <v-spacer/>
                  <v-btn color="primary" 
                     class="px-4"
                     @click="buildReport(1)"
                     :disabled="!(isBuildChart || isBuildTable) || !points || points.length<=0"
                     >
                     Build
                  </v-btn>
               </v-card-actions>
            </v-card>
         </v-col>
      </v-row>

      <!-- Chart -->
      <v-row class="px-1 py-0 mx-0"
         v-if="showChart && !loading">
         <v-col cols="12" class="pa-0" :class="{'px-1': $vuetify.breakpoint.mdAndUp}">
            <v-card class="pa-1" v-if='hasChartContent'>
               <v-card-title v-if="showDownload && !$root.isElectron" class="pt-1 pb-0 ma-0">
                  <v-btn color="primary" class="text-none px-2" @click="exportExcel">
                     <v-icon class="mr-1">mdi-download</v-icon>
                     Download
                  </v-btn>
                  <v-spacer/>
                  <v-btn fab color="primary" x-small :outlined="chartScale!=1" 
                     @click="chartScale=1"
                     class="mr-2">
                     1x
                  </v-btn>
                  <v-btn fab color="primary" x-small :outlined="chartScale!=2" 
                     @click="chartScale=2"
                     class="mr-2">
                     2x
                  </v-btn>
                  <v-btn fab color="primary" x-small :outlined="chartScale!=4"
                     @click="chartScale=4"
                     class="mr-2"
                     >
                     4x
                  </v-btn>
                  <v-btn fab color="primary" x-small :outlined="chartScale!=8"
                     @click="chartScale=8"
                     >
                     8x
                  </v-btn>
               </v-card-title>
               <v-card-text class='pa-0 pb-2 pt-1 ma-0'>
                  <line-chart-points
                     height="320"
                     :loadIndex="loadIndex"
                     :scale='chartScale'
                     :labels='chartLabels'
                     :points='pointsWithData'/>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>

      <!-- Table -->
      <v-row v-if="showTable && !loading"
         class="px-1 py-1 my-0 mx-0">
         <v-col cols='12' class='pa-0'>
            <v-card class="ma-0 pa-1 mt-2" v-if="tableItems">
               <v-card-title v-if="showDownload" class="pt-1 pb-2 ma-0">
                  <v-btn color="primary" class="text-none" @click="exportExcel">
                     <v-icon class="mr-1">mdi-download</v-icon>
                     Excel Export
                  </v-btn>
                  <v-spacer/>
               </v-card-title>
              <v-card-text class="ma-0 pt-0 px-0">
                  <v-data-table
                     dense 
                     :headers="tableHeader"
                     :items="tableItems"
                     :items-per-page="9"
                     class="elevation-0"
                     mobile-breakpoint="0"
                  ></v-data-table>
              </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
let moment = require('moment')
let XLSX = require("xlsx")
export default {
   computed: {
      hasChartContent: function () {
         if (this.pointsWithData && this.pointsWithData.length>0){
               return true
            }
         else {
            return false
         }
      },
   },
   data () {
      return {
         loading: false,
         showTable: false,
         showChart: false,
         loadIndex: new Date().getTime(),
         dateType: null,
         dialogPickPoints: false,
         showDownload:  true,
         points: [],
         curDateObject: null,
         sourcePoints: [],
         activeInterval: 60,
         allIntervals: [
            { label: '1ph', valueInMin: 1},
            { label: '5ph', valueInMin: 5},
            { label: '10ph', valueInMin: 10},
            { label: '15ph', valueInMin: 15},
            { label: '30ph', valueInMin: 30},
            { label: '1 giờ', valueInMin: 60},
            { label: '1 ngày', valueInMin: 1440},
            // { label: '2 hours', valueInMin: 120},
            // { label: '6 hours', valueInMin: 360}
         ],
         chartColors: [
            '#F50057',
            '#5E35B1',
            '#0097A7',
            '#4CAF50',
            '#9E9D24',
            '#FF9800',
            '#795548',
            '#D84315',
            '#607D8B',
            '#757575',
            '#303F9F',
            '#880E4F',
            '#B71C1C'],
         pointsWithData: null,
         chartLabels: [],
         chartScale: 1,
         tableHeader: [],
         tableItems: [],
         isBuildChart: false,
         isBuildTable: true,
         blocks: [],
         reportType: 0,
         socketAskKey: new Date().getTime()
      }
   },
   sockets: {
      callHTTPRequestResult: function (payload) {
         // console.log("callHTTPRequestResult")
         // console.log(payload)
         if(payload && payload.askKey == this.socketAskKey) {
            if(payload.result && payload.result.status=="OK"){
               this.blocks = this.blocks.concat(payload.result.content)
               this.processDataBlocksAfterFetch()
            }
         }
      }
   },
   methods: {
      onPointPicked (points) {
         // console.log('onPointPicked')
         // console.log(points)
         this.points = points
         this.dialogPickPoints = false
         this.points.forEach((p, i) => {
            p.color = this.chartColors[i]||"#000000"
         })
      },
      onDateChanged (dateObj) {
         this.curDateObject = dateObj
      },
      async fetchData(){
         //return new Promise (async (resolve, reject) => {
            if(!this.points) {
               return []
            }

            let self = this
            let fetchPath = this.$root.logApi + "blocks/filter"
            let pointNamesHotBlock = this.points.map(p => p.reportName)
            let pointNames = this.points.map(p => 'masterDev-' + p.reportName)
            let fromStr  = moment(this.curDateObject.date).format("YYYY-MM-DD HH:mm:00")
            let toStr = moment(this.curDateObject.toDate).add(-1, 'm').format("YYYY-MM-DD HH:mm:00")

            let fromYearStr = this.curDateObject.dateStrFull.substring(0,4)
            let toYearStr = moment(this.curDateObject.toDate).add(-1, 'm').format("YYYY-MM-DD HH:mm:00").substring(0,4)

            let filterArr = []
            let PAGE_SIZE = 3
            let numOfPointBreak = Math.ceil(this.points.length/PAGE_SIZE)
            for(let i = 0;i<numOfPointBreak; i++){
               let pickedPointNames = pointNames.slice(i*PAGE_SIZE, Math.min(pointNames.length, (i+1)*PAGE_SIZE))
               let pickedPointNamesHotBlock = pointNamesHotBlock.slice(i*PAGE_SIZE, Math.min(pointNames.length, (i+1)*PAGE_SIZE))
               
               if (fromYearStr == toYearStr) {
                  self.loading = true
                  let f = {
                     includeRec: 'true',
                     from: fromStr,
                     to: toStr,
                     pointNames: pickedPointNames
                  }
                  if(pointNames.length<20){
                     f.pointNamesHotBlock = pickedPointNamesHotBlock
                  }
                  filterArr.push(f)
               } else {
                  let f1 = {
                     includeRec: 'true',
                     from: fromStr,
                     to: moment(fromStr)
                        .endOf("year")
                        .format("YYYY-MM-DD 23:59:59"),
                     pointNames: pickedPointNames
                  }
                  let f2 = {
                     includeRec: 'true',
                        from: moment(toStr)
                           .startOf("year")
                           .format("YYYY-MM-DD 00:00:00"),
                        to: toStr,
                        pointNames: pickedPointNames
                  }

                  if(pointNames.length<20){
                     f2.pointNamesHotBlock = pickedPointNamesHotBlock
                  }

                  filterArr.push(f1)
                  filterArr.push(f2)
               }
            }

            self.loading = true
            let returnBlocks = []

            //console.log(filterArr)

            this.blocks = []

            for(let i = 0; i< filterArr.length; i++){
               if (this.$root.isRemote) {
                  try {
                     this.callApiOverOTA(
                        "PUT",
                        fetchPath,
                        filterArr[i]
                        )
                     
                  } catch (err) {
                     console.log(err)
                     if(i==0){
                        alert(err)
                     }
                  }
               } else {
                  let content = await this.fetchBlockDataDirect(filterArr[i])
                  this.blocks = this.blocks.concat(content)
               }
            }

            this.processDataBlocksAfterFetch()
            
            self.loading = false
            return returnBlocks
      },
      processDataBlocksAfterFetch () {
         this.pointsWithData = []
         // curDateObject
         if(this.points.length<=0) return
         // console.log(this.points)
         // chart
         this.chartLabels = this.buildLabels()
         // let beginBuild = new Date()
         this.points.forEach((p, index) => {
            this.pointsWithData.push({
               reportName: p.reportName,
               displayName: p.name + " (" + p.unit + ")",
               color: p.color,
               backgroundColor: p.backgroundColor,
               active: p.active,
               dataItems: this.takeDataForPoints(this.blocks, p.reportName, index, p)
            })
         })
         // table  
         this.tableHeader = [
            {
               text: 'Date',
               align: 'start',
               sortable: true,
               value: 'date',
               width: 150,
            }]

         this.points.forEach((point) => {
            this.tableHeader.push({
               text: point.name + '(' + point.unit + ')',
               align: 'end',
               value: point.reportName
            })
         })
         this.tableItems = []
         this.pointsWithData.forEach((point) => {
            point.dataItems.forEach((dataItem, rIndex) => {
               if(!this.tableItems[rIndex]){
                  let row = {
                     date: moment(this.curDateObject.date).add(this.activeInterval*rIndex, 'minutes').format("DD-MM-YYYY HH:mm")
                  }
                  row[point.reportName] = dataItem
                  this.tableItems.push(row)
                  
               } else {
                  if(point.reportName.startsWith('airCon')){
                      this.tableItems[rIndex][point.reportName] = point.slace==dataItem?'On':'Off'
                  } else {
                     this.tableItems[rIndex][point.reportName] = dataItem
                  }
                  
               }
            })
         })

         if(this.reportType==0){
            this.exportExcel()
         }
         // console.log("End fetch data...")
         this.$forceUpdate()
      },
      onSelectPointChanged () {
         if(!this.pointsWithData || this.pointsWithData.length<=0) return
         this.points.forEach(p => {
            let matchPoint = this.pointsWithData.find(pWithData => pWithData.reportName == p.reportName)
            if(matchPoint){
               matchPoint.active = p.active
            }
         })
         this.loadIndex = this.loadIndex + 1
      },
      async buildReport (reportType) {
         this.reportType = reportType
         if(reportType==0){
            this.showChart = false
            this.showTable = false
         }
         if(reportType==1){
            this.showChart = this.isBuildChart
            this.showTable = this.isBuildTable
         }
         this.fetchData()
      },
      buildLabels () {
         let labels = []
         let startDate = this.curDateObject.date
         let endDate = new Date()
         let numberOfItems = 0
         if(this.curDateObject.type == 'day') {
            endDate = moment(startDate).add(1, 'days').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('HH:mm')
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'week') {
            endDate = moment(startDate).add(7, 'days').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD HH:mm')
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'month') {
            endDate = moment(startDate).add(1, 'months').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD HH') + 'h'
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'year') {
            endDate = moment(startDate).add(1, 'years').toDate()
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000))
            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD/MM')
               labels.push(dateStr)
            }
         }

         if(this.curDateObject.type == 'range') {
            endDate = this.curDateObject.toDate
            numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/(this.activeInterval*60000)) + 1

            for(let i=0; i<numberOfItems; i++){
               let dateStr = moment(startDate).add(this.activeInterval*i, 'minutes').format('DD HH:mm')
               labels.push(dateStr)
            }
         }
         // console.log(numberOfItems)
         return labels
      },
      takeDataForPoints(items, reportName, pointIndex, point) {
         let retItem = []
         let blockItems = items.filter(p => p.name == reportName)
         if(blockItems && blockItems.length>0){
            let recItems = []
            blockItems.forEach((block) => {
               // if(reportName == 'accuEnergy') {
               //    console.log(block.date)
               //    console.table(block.records.map(r => { return {m:r.m, q:r.q, v:r.v}}))

               // }
               let blockDate = new Date(block.date)
               
               if(block.records && block.records.length>0){
                  block.records.forEach(rec => {
                     let recDate = moment(blockDate).add(rec.m, 'minutes').toDate()
                     if(rec.q >= 0){
                        let rValue = rec.v
                        if(point.scale != undefined){
                           rValue = rValue * point.scale
                        }
                        if(rValue == 0) rValue = null
                        recItems.push({
                           date: recDate,
                           dateInMili: recDate.getTime(),
                           value: rValue
                           // value: Math.round(Math.random()*100)+100*pointIndex
                        })
                     } else {
                        recItems.push({
                           date: recDate,
                           dateInMili: recDate.getTime(),
                           value: null
                           // value: Math.round(Math.random()*100)+100*pointIndex
                        })
                     }
                  })
               }
            })
            // console.log(pointIndex)
            let startDate = this.curDateObject.date
            let endDate = this.curDateObject.toDate
            let startDateMili  = startDate.getTime()
            let intervalInMili = this.activeInterval*60000
            let numberOfItems = Math.floor((endDate.getTime()-startDate.getTime())/intervalInMili)
            for(let i=0; i<numberOfItems; i++){
               // items.push(Math.round(Math.random()*200)+500 + index*100)
               let inRangeItems = recItems.filter(item => item.dateInMili >=startDateMili+intervalInMili*i && item.dateInMili <= startDateMili+intervalInMili*(i+1))
               // console.log(`--------- ${i} ------------`)
               // console.log(inRangeItems)
               if(!reportName.startsWith('accu')) {
                  if(inRangeItems.length>0) {
                     let v = Math.round(10*Number(inRangeItems[0].value))/10
                     retItem.push(v)
                  } else {
                     retItem.push(null)
                  }
               }
               if(reportName.startsWith('accu')) {
                  console.log(`${reportName} inRangeItems ${inRangeItems.length}`)
                  if(inRangeItems.length>1) {
                     let accuValue = 0
                     for (let j=1;j<inRangeItems.length;j++){
                        if(inRangeItems[j].value>= inRangeItems[j-1].value) {
                           accuValue += inRangeItems[j].value - inRangeItems[j-1].value
                        }
                        if (inRangeItems[j].value < inRangeItems[j-1].value) {
                           // accuValue += inRangeItems[j].value
                           console.log(`data reset ${inRangeItems[j-1].value} ${inRangeItems[j].value}`)
                           console.log(inRangeItems[j-1])
                           console.log(inRangeItems[j])
                        }
                     }
                     accuValue = Math.round(accuValue*10)/10
                     retItem.push(accuValue)
                  } else if (inRangeItems.length==1) {
                     retItem.push(0)
                  }
                  else {
                     retItem.push(null)
                  }
               }
            }
         }
         
         return retItem
      },
      exportExcel() {
         let wb = XLSX.utils.book_new();
         let data = this.tableItems;
         let header = this.tableHeader.map((theader) => theader.text)
         data = data.map(row => {
            let returnRow = {}
            returnRow[header[0]] = row['date']
            this.points.forEach(point => {
               returnRow[point.name + '(' + point.unit + ')'] = row[point.reportName]
            })
            return returnRow
         })

         let dataWS = XLSX.utils.json_to_sheet(data, {
            header: header
         });
         let wscols = [
            {wpx: 160}
         ]
         header.forEach((f, index) => {if(index>0) wscols.push({wpx: 120})})
         dataWS['!cols'] = wscols
         XLSX.utils.book_append_sheet(wb, dataWS, "Report");
         const fileName = this.genExcelFileName(this.dateRange);
         XLSX.writeFile(wb, fileName);
      },
      genExcelFileName() {
         let filename = ""   
         if(this.points.length>0){
            filename = this.points[0].reportName
         }
         filename = filename +
         '-' +
         new moment(this.curDateObject.date).format("DD-MM-YYYY") +
         ".xlsx";
         return filename;
      },
      callApiOverOTA (method, url, body) {
         this.$socket.emit('callHTTPRequest', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey,
            method: method,
            url: url,
            body: body
         })
      },
      async fetchBlockDataDirect (payload) {
         // console.log("Call " +  this.$root.lanLogApi + `blocks/filter`)
         // console.log(payload)
         let res = await this.axios.put(
            this.$root.lanLogApi + `blocks/filter`,
            payload
         )
         // console.log(res.data)

         if (res.data.status == 'OK') {
            return res.data.content
         } else {
            alert(res.data.message)
         }
      }
   }
}
</script>

<style>

</style>